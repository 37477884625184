import React from 'react';
import logo from '../assets/fincred-logo.jpg'; 

const LoadingLogo = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <img 
        src={logo} 
        alt="Loading Logo" 
        className="w-40 h-50 animate-fade-in-out" 
      />
    </div>
  );
};

export default LoadingLogo;
