import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavigationBar from './components/navigationbar';
import Footer from './components/footer';
import Home from './pages/home';
import AboutUs from './pages/about-us';
import ContactUs from './pages/contact-us';
import Login from './pages/login';
import ProcessPage from './pages/ProcessPage';
import DsaConnector from './components/DsaConnector'
import { FinancialServices, CertificationServices, BusinessRegistration, OtherServices } from './components/all-services';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(()=>{
    const isUserDataPresentInLocalStorage = localStorage.getItem('userData') || null
    if(isUserDataPresentInLocalStorage) setIsLoggedIn(true)
    else setIsLoggedIn(false)
  },[])

  return (
    <Router>
      {isLoggedIn ? (
        <ApplicationRouter setIsLoggedIn={setIsLoggedIn}/>
      ) : (
        <LandingPageRouter setIsLoggedIn={setIsLoggedIn} />
      )}
    </Router>
  );
}

const LandingPageRouter = ({ setIsLoggedIn }) => {
  return (
    <div>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<FinancialServices />} />
        <Route path="/services/financial-services" element={<FinancialServices />} />
        <Route path="/services/certification-services" element={<CertificationServices />} />
        <Route path="/services/business-registration" element={<BusinessRegistration />} />
        <Route path="/services/other-services" element={<OtherServices />} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/dsa-connector" element={<DsaConnector />} />``
      </Routes>
      <Footer />
    </div>
  );
};

const ApplicationRouter = ({setIsLoggedIn}) => {
  return (
    <div>
      <Routes>
        <Route path="/process" element={<ProcessPage setIsLoggedIn={setIsLoggedIn}/>} />
        <Route path="*" element={<Navigate to="/process" />} /> {/* Redirect any unknown routes to /process */}
      </Routes>
    </div>
  );
};

export default App;

