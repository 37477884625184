import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos'; 
import Photo1 from '../assets/growing-trees.jpeg';
import Client1 from '../assets/Client1.jpeg';
import Client2 from '../assets/Client2.jpeg';
import Client3 from '../assets/Client3.jpeg';
import Client4 from '../assets/Client4.jpeg';
import Client5 from '../assets/Client5.jpeg';

const ClientLogo = ({ src, alt }) => (
  <div className="bg-white p-4 m-10 rounded-lg shadow-lg flex-shrink-0">
    <img src={src} alt={alt} className="h-24 w-48 object-contain" /> 
  </div>
);

const ClientsSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section
      className="relative bg-cover bg-center py-20"
      style={{ backgroundImage: `url(${Photo1})` }}
    >
      {/* Background overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 text-center text-white px-4">
        <h2 className="text-4xl font-bold mb-4" data-aos="fade-up">Clients</h2>
        <p className="text-lg mb-8" data-aos="fade-up" data-aos-delay="100">Few of our clients</p>

        {/* Infinite Scroll Container */}
        <div className="relative overflow-hidden whitespace-nowrap mx-auto w-full max-w-4xl">
          <div className="marquee-wrapper">
            <div className="marquee-content">
              <ClientLogo src={Client1} alt="Client 1" />
              <ClientLogo src={Client2} alt="Client 2" />
              <ClientLogo src={Client3} alt="Client 3" />
              <ClientLogo src={Client4} alt="Client 4" />
              <ClientLogo src={Client5} alt="Client 5" />
              {/* Duplicate the images for seamless looping */}
              <ClientLogo src={Client1} alt="Client 1" />
              <ClientLogo src={Client2} alt="Client 2" />
              <ClientLogo src={Client3} alt="Client 3" />
              <ClientLogo src={Client4} alt="Client 4" />
              <ClientLogo src={Client5} alt="Client 5" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsSection;


