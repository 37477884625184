import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/fincred-logo.jpg';

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-lg font-poppins bg-gradient-to-r from-blue-100 to-white ">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <div>
            <Link to="/" className="flex items-center py-4 px-2">
              <img src={Logo} alt="Logo" className="h-20"/>
            </Link>
          </div>

          {/* Primary Navbar items */}
          <div className="hidden md:flex items-center justify-center space-x-10 flex-grow">
            <Link to="/" className="py-4 px-2 text-gray-500 text-lg">Home</Link>
            <Link to="/about-us" className="py-4 px-2 text-gray-500 hover:text-blue-500 transition duration-300 text-lg hover:border-b-4 hover:border-indigo-500">About Us</Link>
            <Link to="/services" className="py-4 px-2 text-gray-500 hover:text-blue-500 transition duration-300 text-lg hover:border-b-4 hover:border-indigo-500">Services</Link>
            <Link to="/contact-us" className="py-4 px-2 text-gray-500 hover:text-blue-500 transition duration-300 text-lg hover:border-b-4 hover:border-indigo-500">Contact Us</Link>
          </div>

          {/* Login and Sign Up buttons */}
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/login" className="py-2 px-4 text-gray-500 border border-gray-500 rounded hover:bg-gray-100 transition duration-300">Login</Link>
            <Link to="/login" className="py-2 px-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300">Sign Up</Link>
            <Link to="/dsa-connector" className="py-2 px-4 text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-300"> Join DSA Connector</Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button onClick={toggleMenu} className="outline-none mobile-menu-button">
              <svg className="w-6 h-6 text-gray-500 hover:text-blue-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${isOpen ? "block" : "hidden"} mobile-menu`}>
        <ul>
          <li><Link to="/" className="block text-sm px-2 py-4 text-white bg-blue-500 font-semibold">Home</Link></li>
          <li><Link to="/about-us" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">About Us</Link></li>
          <li><Link to="/services/financial-services" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">Services</Link></li>
          <li><Link to="/contact-us" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">Contact Us</Link></li>
          <li><Link to="/login" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">Login</Link></li>
          <li><Link to="/login" className="block text-sm px-2 py-4 hover:bg-blue-500 transition duration-300">Sign Up</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;