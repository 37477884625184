import React from 'react';
import AboutImage from '../assets/home-about-img.jpg'; 

const AboutUs = () => {
  return (
    <section className="flex flex-col items-center justify-center my-16 px-4 md:px-8 max-w-screen-xl mx-auto">
      {/* Headline */}
      <div className="mb-12 text-center" data-aos="fade-up" data-aos-duration="1000">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">About Us</h1>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full">
        {/* Left Side: Image */}
        <div 
          className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8"
          data-aos="fade-right" 
          data-aos-duration="1000"
        >
          <img 
            src={AboutImage} 
            alt="About Fincred" 
            className="rounded-lg shadow-lg w-full" 
          />
        </div>

        {/* Right Side: Content */}
        <div 
          className="w-full md:w-1/2 text-center md:text-left"
          data-aos="fade-left" 
          data-aos-duration="1000"
        >
          <h2 className="text-3xl font-bold mb-4 relative">
            <span className="inline-block pb-2 border-b-4 border-blue-500">
              Fincred
            </span>
          </h2>
          <p className="text-lg text-gray-700 mb-4">
            At Fincred, we are committed to providing exceptional financial services to help businesses thrive. Our experienced team offers expert guidance in financial management, business certification, and more. With a focus on trust and transparency, Fincred ensures that every client receives tailored solutions that drive success.
          </p>
          <a href="/about-us" className="text-blue-500 hover:text-blue-700 font-semibold transition duration-300">
            Know More
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
