import CryptoJS from 'crypto-js';

const getErrorMessages = (errorJson) => {
    const errorMessage = errorJson?.error?.message?.toUpperCase().replace(/_/g, ' ');
    return errorMessage || "Something went wrong"
}

const decryptData = (encryptedData) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    console.log("API Key:", process.env.REACT_APP_FIREBASE_API_KEY);
    if (!encryptionKey) {
      console.error("Encryption key is missing.");
      return null;
    }

    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Failed to decrypt user data:", error);
      return null;
    }
  }

const removeSpecialCharactersAndGetText = (stringWithSpecialCharcters) => {
    return stringWithSpecialCharcters
    .split(/[^a-zA-Z0-9]+/)  
    .map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() 
    )
    .join(' ');
}

export {
    getErrorMessages,
    decryptData,
    removeSpecialCharactersAndGetText
}