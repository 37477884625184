import React, { useState } from 'react';
import { FIREBASE_AUTH, FIREBASE_DB } from '../firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getErrorMessages } from '../utils';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import CryptoJS from 'crypto-js';

const DsaConnector = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    occupation: '',
    email: '',
    mobile: '',
    panNo: '',
    gstNo: '',
    password: '',
  });
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validatePan = (pan) => {
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
    return panRegex.test(pan);
  };

  const validateGst = (gst, pan) => {
    if(!gst) return true
    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z][A-Z0-9]{3}$/;
    return gstRegex.test(gst) && gst.includes(pan);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validateForm = () => {
    const newErrors = {};
    debugger
    if (!formData.name) newErrors.name = 'Name is required.';
    if (!formData.address) newErrors.address = 'Address is required.';
    if (!formData.occupation) newErrors.occupation = 'Occupation is required.';
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Enter a valid email.';
    }
    if (!formData.mobile) {
      newErrors.mobile = 'Mobile number is required.';
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = 'Enter a valid 10-digit mobile number.';
    }
    if (!formData.panNo) {
      newErrors.panNo = 'PAN number is required.';
    } else if (!validatePan(formData.panNo)) {
      newErrors.panNo =
        'PAN must have 5 alphabets, 4 digits, and 1 alphabet (e.g., ABCDE1234F).';
    }

    if (!validateGst(formData.gstNo, formData.panNo)) {
      newErrors.gstNo =
        'GST must start with 2 digits, include PAN, and end with 3 alphanumeric characters.';
    }

    if (!formData.password) {
        newErrors.password = 'Password is required.';
    } else if (!validatePassword(formData.password)) {
        newErrors.password =
        'Password must be at least 8 characters, include one uppercase, one lowercase, one number, and one special character.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted successfully:', formData);
      try {
        debugger
        const { name, email, password } = formData;
        const createdUser = await createUserWithEmailAndPassword(FIREBASE_AUTH, email, password);
        if (createdUser) {
          await setDoc(doc(FIREBASE_DB, 'users', createdUser.user.uid), {
            name,
            email,
            userType: 'dsa_connector',
          });
        }
        navigate('/login');
      } catch (error) {
        const errorMessage = getErrorMessages(error);
        setErrors(errorMessage);
      }
      setFormData({
        name: '',
        address: '',
        occupation: '',
        email: '',
        mobile: '',
        panNo: '',
        gstNo: '',
        password: '',
      });
    }

  };

  return (
    <div className="w-8/12 mx-auto mt-10 mb-10 p-6 border rounded shadow-lg">
      <h1 className="text-2xl font-bold mb-6">Sign-Up DSA Connector </h1>
      <form onSubmit={handleSubmit}>
        {[
          { label: 'Name', name: 'name', type: 'text' },
          { label: 'Address', name: 'address', type: 'text' },
          { label: 'Occupation', name: 'occupation', type: 'text' },
          { label: 'Email', name: 'email', type: 'email' },
          { label: 'Mobile', name: 'mobile', type: 'text' },
          { label: 'PAN No', name: 'panNo', type: 'text' },
          { label: 'GST No', name: 'gstNo', type: 'text' },
        ].map(({ label, name, type }) => (
          <div key={name} className="mb-4">
            <label className="block font-medium mb-2 text-blue-500" htmlFor={name}>
              {label}
            </label>
            <input
              type={type}
              id={name}
              name={name}
              value={formData[name]}
              onChange={handleChange}
              className={`w-full p-2 border ${
                errors[name] ? 'border-red-500' : 'border-gray-300'
              } rounded focus:outline-none focus:ring`}
            />
            {errors[name] && (
              <p className="text-red-500 text-sm mt-1">{errors[name]}</p>
            )}
          </div>
        ))}

        {/* Password Field with Toggle */}
        <div className="mb-4">
          <label className="block font-medium mb-2 text-blue-500" htmlFor="password">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={`w-full p-2 border ${
                errors.password ? 'border-red-500' : 'border-gray-300'
              } rounded focus:outline-none focus:ring`}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-gray-500"
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">{errors.password}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default DsaConnector;



