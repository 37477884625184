import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import { FiX } from 'react-icons/fi';
import { doc, setDoc } from 'firebase/firestore';
import { FIREBASE_DB } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid'; 

import { STATUS } from '../constants/service-constants';

const Modal = ({ toggleModal, userType, userID }) => {
  const [formData, setFormData] = useState({
    businessName: '',
    ownerName: '',
    mobileNumber: '',
    address: '',
    pan: '',
    gst: '',
    loanType: '',
    loanAmount: '',
    mutualFund: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(formData), encryptionKey).toString();
    const uniqueID = uuidv4();

    try {
      await setDoc(doc(FIREBASE_DB, 'loanfiles', uniqueID), {
        data: encryptedData,
        userID: userID,
        userType: userType,
        status: STATUS.SENT_FOR_PROCESS
      });
      console.log("Data successfully encrypted and saved to Firestore.");
      toggleModal();
      setFormData({
        businessName: '',
        ownerName: '',
        mobileNumber: '',
        address: '',
        pan: '',
        gst: '',
        loanType: '',
        loanAmount: '',
        mutualFund: '',
      });
    } catch (error) {
      console.error("Failed to save data:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30 transition-opacity duration-300 ease-in-out">
      <div className="bg-white p-6 rounded-lg w-full max-w-3xl overflow-y-auto max-h-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Add File</h2>
          <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700">
            <FiX className="text-2xl" />
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="space-y-4">
            {['businessName', 'ownerName', 'address', 'pan', 'gst', 'loanType', 'mutualFund'].map((field, index) => (
              <input
                key={index}
                type="text"
                name={field}
                value={formData[field]}
                onChange={handleInputChange}
                placeholder={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                className="w-full p-2 border border-gray-300 rounded"
              />
            ))}
            <input
              type="number"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleInputChange}
              placeholder="Mobile Number"
              className="w-full p-2 border border-gray-300 rounded"
            />
            <input
              type="number"
              name="loanAmount"
              value={formData.loanAmount}
              onChange={handleInputChange}
              placeholder="Loan Amount"
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 mt-6 rounded hover:bg-blue-600 transition duration-300 w-full">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
