import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPiggyBank, FaCertificate, FaBriefcase, FaEllipsisH } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const services = [
  {
    title: "Financial Services",
    description: `Our financial experts are here to help you navigate complex financial landscapes, 
    offering personalized advice on wealth management, investment strategies, and financial planning. 
    We work closely with you to understand your financial goals, ensuring that your investments are 
    aligned with your long-term objectives. Whether you are planning for retirement or seeking to 
    diversify your portfolio, we have the expertise to guide you every step of the way.`,
    icon: <FaPiggyBank className="text-6xl text-black mb-4 transition duration-300" />,
    link: "/services/financial-services"
  },
  {
    title: "Certification Services",
    description: `We provide comprehensive certification services to ensure your business meets all 
    required standards, boosting your credibility and market position. Our certification process 
    is thorough and designed to address all regulatory requirements, making sure your business 
    operations are fully compliant. With our certification services, you can enhance your brand's 
    reputation and gain the trust of your clients and stakeholders.`,
    icon: <FaCertificate className="text-6xl text-black mb-4 transition duration-300" />,
    link: "/services/certification-services"
  },
  {
    title: "Business Registration",
    description: `From start to finish, we offer full support in business registration, making the process 
    seamless and stress-free, ensuring your business is legally compliant. Our team handles all the 
    paperwork and liaises with the relevant authorities on your behalf, allowing you to focus on 
    building your business. We also provide ongoing support to ensure that your business remains 
    compliant with all legal requirements as it grows.`,
    icon: <FaBriefcase className="text-6xl text-black mb-4 transition duration-300" />,
    link: "/services/business-registration"
  },
  {
    title: "Other Services",
    description: `Explore our range of additional services designed to meet diverse business needs, including 
    consultancy, compliance, and more. Our team of experts is ready to provide tailored solutions 
    that address the unique challenges of your business. Whether you need strategic advice or 
    operational support, our comprehensive services are designed to help your business thrive in 
    a competitive market.`,
    icon: <FaEllipsisH className="text-6xl text-black mb-4 transition duration-300" />,
    link: "/services/other-services"
  }
];

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="font-poppins ">
      <section className="py-12 bg-white bg-gradient-to-r from-blue-100 to-white ">
        <div className="container mx-auto px-4 text-center h-full flex flex-col justify-center">
          <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-5xl font-bold mb-16 mt-20" data-aos="fade-up">
            Our Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 h-full ">
            {services.map((service, index) => (
              <div 
                key={index} 
                className="bg-white text-black p-8 rounded-lg shadow-2xl flex flex-col justify-between transition duration-300 hover:shadow-lg" 
                data-aos="fade-up"
                data-aos-delay={`${index * 100}`}
              >
                <div className="flex flex-col items-start mb-4">
                  {service.icon}
                  <div className="mt-4">
                    <h3 className="text-3xl font-semibold mb-4">{service.title}</h3>
                    <p className="text-lg mb-6">{service.description}</p>
                  </div>
                </div>
                <Link
                  to={service.link}
                  className="bg-blue-500 text-white font-semibold py-3 px-6 rounded-lg text-center hover:bg-blue-600 transition duration-300 self-start"
                >
                  Know More
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;


