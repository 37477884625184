import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { FIREBASE_DB } from '../firebaseConfig'; 

import { decryptData } from '../utils';
import SideNavigationMenu from '../components/SideNavigationMenu';
import Modal from '../components/Modal';
import AllFiles from '../components/AllFiles';
import LoadingLogo from '../components/LoadingLogo'; 

const ProcessPage = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState('');
  const [userID, setUserID] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('open_files');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = currentPage === 1 ? 10 : 20;
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('userData');
    navigate('/login');
  };

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const decryptedData = decryptData(userData);
      if (decryptedData) {
        setUserType(decryptedData.userType);
        setUserID(decryptedData.uid);
      }
    }
  }, []);


  useEffect(() => {
    const fetchFiles = async () => {
      setIsLoading(true); 
      try {
        const filesCollection = collection(FIREBASE_DB, 'loanfiles');
        let q = null;

        if (userType === 'admin') {
          q =
            activeTab === 'open_files'
              ? query(filesCollection, where('status', '!=', 'closed'))
              : query(filesCollection, where('status', '==', 'closed'));
        } else {
          q =
            activeTab === 'open_files'
              ? query(
                  filesCollection,
                  where('userID', '==', userID),
                  where('status', '!=', 'closed')
                )
              : query(
                  filesCollection,
                  where('userID', '==', userID),
                  where('status', '==', 'closed')
                );
        }

        const querySnapshot = await getDocs(q);
        const fetchedFiles = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const decryptedFiles = createDecrytedFile(fetchedFiles);
        setFilteredFiles(decryptedFiles);
      } catch (error) {
        console.error('Error fetching files:', error);
      } finally {
        setIsLoading(false); 
      }
    };

    if ((userType && userID) || userType === 'admin') {
      fetchFiles();
    }
  }, [userType, userID, activeTab]);

  const createDecrytedFile = (files) => {
    return files.map((file) => {
      const decryptedFileData = decryptData(file?.data);
      return {
        ...decryptedFileData,
        ...file,
      };
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex min-h-screen">
      <SideNavigationMenu
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        handleLogout={handleLogout}
      />

      {isLoading ? (
        <div className="flex justify-center items-center w-full">
          <LoadingLogo /> 
        </div>
      ) : (
        <AllFiles
          isSidebarOpen={isSidebarOpen}
          filteredFiles={filteredFiles}
          userType={userType}
          userID={userID}
          searchQuery={searchQuery}
          currentPage={currentPage}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setSearchQuery={setSearchQuery}
          toggleModal={toggleModal}
          toggleSidebar={toggleSidebar}
          handlePageChange={handlePageChange}
        />
      )}

      {isModalOpen && (
        <Modal
          toggleModal={toggleModal}
          userType={userType}
          userID={userID}
        />
      )}
    </div>
  );
};

export default ProcessPage;

