import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH, FIREBASE_DB } from '../firebaseConfig';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { getErrorMessages } from '../utils';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import CryptoJS from 'crypto-js';

const AuthPage = ({ setIsLoggedIn }) => {
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const navigate = useNavigate();
  const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const loggedInUser = await signInWithEmailAndPassword(
        FIREBASE_AUTH,
        email,
        password
      );
      if (loggedInUser) {
        const userDoc = await getDoc(
          doc(FIREBASE_DB, 'users', loggedInUser.user.uid)
        );
        const userData = {
          uid: loggedInUser.user.uid,
          email: loggedInUser.user.email,
          name: userDoc.data().name,
          userType: userDoc.exists() ? userDoc.data().userType : 'customer',
        };
        setIsLoggedIn(true);
        const encryptedUserData = CryptoJS.AES.encrypt(
          JSON.stringify(userData),
          ENCRYPTION_KEY
        ).toString();
        localStorage.setItem('userData', encryptedUserData);
        navigate('/process');
      }
    } catch (error) {
      console.log('error', error);
      setError(error.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const createdUser = await createUserWithEmailAndPassword(
        FIREBASE_AUTH,
        email,
        password
      );
      if (createdUser) {
        await setDoc(doc(FIREBASE_DB, 'users', createdUser.user.uid), {
          name,
          email,
          userType: userType || 'customer',
        });
      }
      navigate('/login');
      setActiveTab('login');
    } catch (error) {
      const errorMessage = getErrorMessages(error);
      setError(errorMessage);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(FIREBASE_AUTH, email);
      setResetEmailSent(true);
    } catch (error) {
      setError('Failed to send reset email. Please try again.');
      console.error('Password Reset Error:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-50">
      <div className="bg-white p-10 rounded-lg shadow-lg max-w-md w-full" data-aos="fade-up">
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 rounded-t-lg border-b-2 ${
              activeTab === 'login' ? 'text-black border-black' : 'text-blue-600 border-transparent'
            }`}
            onClick={() => {
              setShowForgotPassword(false);
              setActiveTab('login');
            }}
          >
            Login
          </button>
          <button
            className={`px-4 py-2 rounded-t-lg ml-2 border-b-2 ${
              activeTab === 'signup' ? 'text-black border-black' : 'text-blue-600 border-transparent'
            }`}
            onClick={() => {
              setShowForgotPassword(false);
              setActiveTab('signup');
            }}
          >
            Signup
          </button>
        </div>

        {activeTab === 'login' && !showForgotPassword && (
          <div>
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label className="block text-blue-600 font-bold mb-2">Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-6 relative">
                <label className="block text-blue-600 font-bold mb-2">Password:</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span
                  className="absolute right-2 top-9 cursor-pointer text-gray-500 mt-2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
              <button
                type="submit"
                className="w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Login
              </button>
            </form>
            <button
              className="text-blue-600 mt-4 text-sm underline"
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot Password?
            </button>
          </div>
        )}

        {showForgotPassword && (
          <div>
            <label className="block text-blue-600 font-bold mb-2">Enter your email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleForgotPassword}
              className="w-full bg-blue-600 text-white p-2 mt-4 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Send Reset Link
            </button>
            {resetEmailSent && (
              <p className="text-green-500 text-sm mt-2">
                Password reset email sent! Check your inbox.
              </p>
            )}
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <button
              className="text-blue-600 mt-4 text-sm underline"
              onClick={() => setShowForgotPassword(false)}
            >
              Back to Login
            </button>
          </div>
        )}

        {activeTab === 'signup' && (
          <div>
            <form onSubmit={handleSignup}>
              <div className="mb-4">
                <label className="block text-blue-600 font-bold mb-2">Name:</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-blue-600 font-bold mb-2">Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-6 relative">
                <label className="block text-blue-600 font-bold mb-2">Password:</label>
                <input
                  type={showSignupPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-2 border border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span
                  className="absolute right-2 top-9 cursor-pointer text-gray-500 mt-2"
                  onClick={() => setShowSignupPassword(!showSignupPassword)}
                >
                  {showSignupPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>
              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
              <button
                type="submit"
                className="w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Signup
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthPage;
