import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import ServiceCard from './service-cards';
import { 
    FINANCIAL_SERVICE_DATA,
    CERTIFICATION_SERVICE_DATA, 
    BUSINESS_REGISTRATION_DATA,
    OTHER_SERVICES_DATA 
} from '../constants/service-constants';

AOS.init(); 

const SubNavigation = () => (
    <div className="grid grid-cols-2 gap-4 mb-8">
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 flex items-center justify-center">
        <Link
          to="/services/financial-services"
          className="text-blue-500 hover:text-blue-700 font-semibold text-sm sm:text-base"
        >
          Financial Services
        </Link>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 flex items-center justify-center">
        <Link
          to="/services/certification-services"
          className="text-blue-500 hover:text-blue-700 font-semibold text-sm sm:text-base"
        >
          Certification Services
        </Link>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 flex items-center justify-center">
        <Link
          to="/services/business-registration"
          className="text-blue-500 hover:text-blue-700 font-semibold text-sm sm:text-base"
        >
          Business Registration
        </Link>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg shadow-md p-4 flex items-center justify-center">
        <Link
          to="/services/other-services"
          className="text-blue-500 hover:text-blue-700 font-semibold text-sm sm:text-base"
        >
          Other Services
        </Link>
      </div>
    </div>
  );



  const businessLoansData = [
    { title: 'Trade Finance Solutions', aosEffect: 'fade-up' },
    { title: 'Vendor Financing', aosEffect: 'fade-up' },
    { title: 'PO Financing', aosEffect: 'fade-up' },
    { title: 'Reverse Factoring', aosEffect: 'fade-up' },
    { title: 'Purchase/Sales Bill Discounting', aosEffect: 'fade-up' },
    { title: 'Factoring (Domestic & Export)', aosEffect: 'fade-up' },
    { title: 'Dealer Finance', aosEffect: 'fade-up' },
    { title: 'LC Discounting (Domestic & Export)', aosEffect: 'fade-up' },
    { title: 'Factoring Financing', aosEffect: 'fade-up' },
    { title: 'Working Capital Needs', aosEffect: 'fade-up' },
    { title: 'LC/BG', aosEffect: 'fade-up' },
    { title: 'Export & Import Financing', aosEffect: 'fade-up' },
    { title: 'Construction Finances', aosEffect: 'fade-up' },
    { title: 'Equipment Loans', aosEffect: 'fade-up' },
    { title: 'Lease Rental Discounting', aosEffect: 'fade-up' },
  ];
  
  const BusinessLoans = () => {
    return (
      <div className="font-poppins bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-4 text-center">Business Loan Services</h2>
          <div className="w-64 h-1 bg-blue-500 mx-auto mb-8"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {businessLoansData.map((loan, index) => (
              <div
                key={index}
                className="p-6 rounded-lg shadow-lg bg-white text-gray-800"
                data-aos={loan.aosEffect}
                data-aos-duration="1000"
              >
                <h3 className="text-2xl font-semibold">{loan.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  



const FinancialServices = () => (
    <>
        <ServiceCard
            serviceData={FINANCIAL_SERVICE_DATA}
            title={"Financial Services"}
        />
        <BusinessLoans/>
    </>

)


const CertificationServices = () => (
    <ServiceCard
        serviceData={CERTIFICATION_SERVICE_DATA}
        title={"Certification Services"}
    />
);

const BusinessRegistration = () => (
    <ServiceCard
    serviceData={BUSINESS_REGISTRATION_DATA}
    title={"Buisness Registration Services"}
    />
);

const OtherServices = () => (
    <ServiceCard
    serviceData={OTHER_SERVICES_DATA}
    title={"Other Services"}
    />
);

export {
    FinancialServices,
    CertificationServices,
    BusinessRegistration,
    OtherServices,
    SubNavigation
}