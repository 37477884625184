import 'aos/dist/aos.css'; 
import AOS from 'aos'; 
import Contact1 from '../assets/contact-1.jpeg'
import Contact2 from '../assets/contact-2.jpeg'
import Contact3 from '../assets/contact-3.jpeg'

AOS.init(); 

const ContactCard = ({ photo, name, email, phone }) => (
  <div
    className="bg-gradient-to-r from-blue-100 to-white border border-gray-300 rounded-lg shadow-lg p-8 flex flex-col items-center justify-center w-full sm:w-96"
    style={{ height: '32rem' }} 
    data-aos="flip-left"
  >
    <img
      src={photo}
      alt={name}
      className="rounded-full w-40 h-40 mb-6"
    />
    <h3 className="text-xl font-semibold ">{name}</h3>
    <h3 className="text-l font-medium mb-4">Partner</h3>
    <p className="text-gray-500 text-lg">{email}</p>
    <p className="text-gray-500 text-lg">{phone}</p>
  </div>
);

const ContactUs = () => (
  <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-16">
    <h2 className="text-4xl font-bold text-gray-800 mb-12" data-aos="fade-down">Contact Us</h2>
      <div className="flex flex-wrap justify-center gap-8">
        <ContactCard
          photo={Contact1}
          name="Mr. Akshay Chougale"
          email="akshaydchougale@gmail.com"
          phone="9423375547"
        />
        <ContactCard
          photo={Contact2}
          name="Mrs. Bhayashri Sevekari"
          email="bhagyamali19@gmail.com"
          phone="9028317477"
        />
        <ContactCard
          photo={Contact3}
          name="Mrs. Shabenoor Desai"
          email="desai4188@gmail.com"
          phone="8087325795"
        />
    </div>
  </div>
);

export default ContactUs;
