import React, { useState } from 'react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { doc, updateDoc } from 'firebase/firestore';
import { FIREBASE_DB } from '../firebaseConfig';


import { removeSpecialCharactersAndGetText } from '../utils'

import { STATUS } from '../constants/service-constants';

const LoanFile = ({ file, userType }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(removeSpecialCharactersAndGetText(file.status));

  const toggleDetails = () => setIsDetailsOpen(!isDetailsOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  debugger

  const handleStatusUpdate = async (newStatus) => {
    try {
      const fileRef = doc(FIREBASE_DB, 'loanfiles', file.id);
      await updateDoc(fileRef, { status: newStatus });
      setCurrentStatus(removeSpecialCharactersAndGetText(newStatus));
      setIsDropdownOpen(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const getStatusFontColor = (status) => {
    switch (status) {
      case STATUS.SENT_FOR_PROCESS:
        return 'gray';
      case STATUS.UNDER_REVIEW:
        return 'blue';
      case STATUS.IN_PROGRESS:
        return 'green';
      case STATUS.CLOSED:
        return 'rose';
      default:
        return 'gray';
    }
  };

  return (
    <div className="border p-4 bg-white rounded-lg shadow-md mb-4 w-full">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="font-bold text-lg">{file.businessName || 'NA'}</h2>
          <p className="text-gray-600">{file.ownerName || 'NA'}</p>
        </div>
        <div>
          <p className="text-gray-600">PAN: {file.pan || 'NA'}</p>
          <p className="text-gray-600">GST: {file.gst || 'NA'}</p>
        </div>
        <div className="relative">
          <p
            className={`text-${getStatusFontColor(currentStatus)}-600 font-semibold flex items-center cursor-pointer`}
            onClick={toggleDropdown}
          >
            {currentStatus} { userType === 'admin' && <FiChevronDown className="ml-1" />}
          </p>
          {isDropdownOpen && userType === 'admin' && (
            <ul className="absolute right-0 mt-2 bg-white border rounded shadow-lg z-10">
              {Object.values(STATUS).map((status) => (
                <li
                  key={status}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer text-${getStatusFontColor(status)}-600`}
                  onClick={() => handleStatusUpdate(status)}
                >
                  {removeSpecialCharactersAndGetText(status)}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button
          onClick={toggleDetails}
          className="text-blue-500 hover:text-blue-700 transition duration-300 flex items-center"
        >
          View Details
          <FiChevronDown className={`ml-1 transform ${isDetailsOpen ? 'rotate-180' : ''}`} />
        </button>
      </div>
      {isDetailsOpen && (
        <div className="mt-4 border-t pt-4 text-gray-600">
          <div className="flex flex-wrap space-x-4">
            <p>
              <strong>Loan Type:</strong> {file.loanType || 'N/A'}
            </p>
            <p>
              <strong>Loan Amount:</strong> {file.loanAmount || 'N/A'}
            </p>
            <p>
              <strong>Mutual Fund:</strong> {file.mutualFund || 'N/A'}
            </p>
            {userType === 'admin' && (
              <>
                <p>
                  <strong>Contact Number:</strong> {file.mobileNumber || 'N/A'}
                </p>
                <p>
                  <strong>User Type:</strong> {file.userType}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const HeaderNavigation = ({ isSidebarOpen,searchQuery ,activeTab, setActiveTab, setSearchQuery, toggleSidebar, toggleModal }) => {

    

    return (
        <div className="flex items-center justify-between p-3 bg-white shadow-md fixed top-0 left-0 right-0 z-10 h-16">
            {!isSidebarOpen && (
                <button onClick={toggleSidebar} className="text-2xl text-gray-700 mr-4 focus:outline-none">
                    <FiMenu />
                </button>
            )}
            <div className="flex items-center flex-1 ml-5">
                {/* Sub-Navigation Tabs */}
                <div className="flex space-x-4">
                    <button
                        className={`px-4 py-2 border-b-4 ${activeTab === 'open_files' ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'} transition-colors duration-300`}
                        onClick={() => setActiveTab('open_files')}
                    >
                        Open Files
                    </button>
                    <button
                        className={`px-4 py-2 border-b-4 ${activeTab === 'close_files' ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'} transition-colors duration-300`}
                        onClick={() => setActiveTab('close_files')}
                    >
                        Close Files
                    </button>
                </div>

                {/* Search Bar */}
                <div className="flex ml-auto">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search files..."
                        className="px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none"
                    />
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 transition duration-300"
                    >
                        Search
                    </button>
                </div>
            </div>
            <button onClick={toggleModal} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 ml-4">
                Add File
            </button>
        </div>
    )
}


const AllFiles = ({
  isSidebarOpen,
  filteredFiles,
  userID,
  userType,
  searchQuery,
  setSearchQuery,
  toggleSidebar,
  toggleModal,
  activeTab,
  setActiveTab,
}) => {
  // Filter files based on the search query
  const filteredSearchFiles = filteredFiles.filter((file) => {
    const search = searchQuery.toLowerCase();
    return (
      file.businessName?.toLowerCase().includes(search) ||
      file.ownerName?.toLowerCase().includes(search) ||
      file.pan?.toLowerCase().includes(search) ||
      file.gst?.toLowerCase().includes(search)
    );
  });

  return (
    <div className="flex-1 bg-gray-100">
      <HeaderNavigation
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        toggleModal={toggleModal}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div className="flex-1 bg-gray-100 pt-20 px-8">
        {filteredSearchFiles.length > 0 ? (
          filteredSearchFiles.map((file) => (
            <LoanFile key={file.id} file={file} userType={userType} />
          ))
        ) : (
          <p className="text-xl text-gray-600">No files match your search.</p>
        )}
      </div>
    </div>
  );
};

export default AllFiles;
