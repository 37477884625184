
import React, { useState, useEffect } from 'react';
import BackgroundImage1 from '../assets/bg1.jpg';
import BackgroundImage2 from '../assets/bg2.jpg';
import BackgroundImage3 from '../assets/bg3.jpg';

const images = [BackgroundImage1, BackgroundImage2, BackgroundImage3];
const texts = [
  "At Fincred, we provide expert financial advisory services to help you navigate complex investment landscapes and achieve maximum returns. Our team is dedicated to your financial growth and success.",
  "We offer tailored financial plans that align with your unique goals, ensuring a secure and prosperous future. Let Fincred guide you with confidence towards financial stability and growth.",
  "Our comprehensive financial analysis empowers you to make informed decisions. With Fincred by your side, you can grow your wealth and achieve financial independence."
];

const mottos = [
  "Your Partner in Financial Growth",
  "Secure Your Future with Fincred",
  "Empowering You to Make Informed Decisions"
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    const lastIndex = images.length - 1;
    const resetIndex = currentIndex === lastIndex;
    const index = resetIndex ? 0 : currentIndex + 1;
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="relative h-[620px]">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
          />
        ))}
      </div>
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="absolute inset-0 flex items-center justify-center text-center text-white p-4">
        <div className="font-poppins">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">{mottos[currentIndex]}</h1>
          <p className="text-lg md:text-2xl max-w-3xl mx-auto">{texts[currentIndex]}</p>
        </div>
      </div>
    </div>
  );
};

export default Carousel;