import React from 'react'
import {  FiArrowLeft } from 'react-icons/fi';

const SideNavigationMenu = ({isSidebarOpen, toggleSidebar, handleLogout}) => {
  return (
    <div className={`fixed top-0 left-0 h-full w-60 bg-gray-800 text-white p-5 transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} z-20`}>
          <button onClick={toggleSidebar} className="text-2xl mb-6 focus:outline-none">
            <FiArrowLeft />
          </button>
          <h2 className="text-2xl font-bold mb-6">Menu</h2>
          <ul className="space-y-4">
            <li className="cursor-pointer hover:text-gray-300">Files</li>
            <li className="cursor-pointer hover:text-gray-300" onClick={handleLogout}>Logout</li>
          </ul>
        </div>
  )
}

export default SideNavigationMenu