import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/fincred-logo.jpg';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-100 to-white text-white font-poppins py-8">
      <div className="container mx-auto px-4">
        <div className="w-full max-w-6xl flex flex-wrap justify-between items-start">
          {/* Section 1: Logo */}
          <div className="w-full sm:w-auto flex justify-center sm:justify-start mb-6 sm:mb-0">
            <img src={Logo} alt="Fincred Logo" className="h-48" />
          </div>

          {/* Section 2: Main Links */}
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
            <h3 className="text-lg font-semibold mb-4 text-neutral-900">Main Links</h3>
            <nav className="space-y-2">
              <Link
                to="/"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                About Us
              </Link>
              <Link
                to="/services"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Services
              </Link>
              <Link
                to="/contact-us"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Contact Us
              </Link>
            </nav>
          </div>

          {/* Section 3: Our Services */}
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
            <h3 className="text-lg font-semibold mb-4 text-neutral-900">Our Services</h3>
            <nav className="space-y-2">
              <Link
                to="/services/financial-services"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Financial Services
              </Link>
              <Link
                to="/services/certification-services"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Certification Services
              </Link>
              <Link
                to="/services/business-registration"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Business Registration
              </Link>
              <Link
                to="/services/other-services"
                className="text-violet-700 hover:text-blue-500 hover:scale-110 transition duration-300 block"
              >
                Other Services
              </Link>
            </nav>
          </div>

          {/* Section 4: Branch Office */}
          <div className="w-full sm:w-auto mb-6 sm:mb-0">
            <h3 className="text-lg font-semibold mb-4 text-neutral-900">Branch Office</h3>
            <address className="not-italic space-y-2 text-violet-700">
              <p>H-3 3rd floor Prestige House</p>
              <p>Civil Hospital Chowk </p>
              <p>Sangli - 416416</p>
            </address>
          </div>

          {/* Section 5: Head Office */}
          <div className="w-full sm:w-auto">
            <h3 className="text-lg font-semibold mb-4 text-neutral-900">Head Office</h3>
            <address className="not-italic space-y-2 text-violet-700">
              <p>SC-9 2nd floor</p>
              <p>Ace-Arcade near Lucky Bazar 9th Lane Rajarampuri</p>
              <p>Kolhapur - 416008</p>
              <p>Email: fincredllp@gmail.com</p>
              <p>Phone: 9423375547</p>
            </address>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;






