
const FINANCIAL_SERVICE_DATA = [
    {
      title: 'Home Loan',
      content: 'Home Loans offer financing for the purchase of residential properties. We provide competitive interest rates and flexible repayment options to help you achieve your dream of homeownership.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Loan Against Property',
      content: 'Loan Against Property allows businesses to unlock the value of their property to raise funds for various needs. We offer attractive interest rates and flexible repayment options.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Car Loan',
      content: 'Car Loans provide funding for the purchase of new or used vehicles. We offer fast approval and flexible terms to make owning a car easier and more affordable.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Personal Loan',
      content: 'Personal Loans provide funds for a wide range of personal needs, from medical emergencies to travel expenses. We offer quick disbursement and competitive interest rates.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Education Loan',
      content: 'Education Loans help students finance their education, covering tuition fees, books, and living expenses. We offer flexible repayment options to ensure that education is accessible to all.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Gold Loan',
      content: 'Gold Loans provide instant liquidity by pledging gold jewelry or coins. We offer attractive interest rates and flexible repayment terms to meet your short-term financial needs.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Term Loans',
      content: 'Term Loans provide businesses with the capital needed for expansion, equipment purchase, and other long-term investments. We offer customized loan solutions to meet your business goals.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Cash Credit / Overdraft',
      content: 'Cash Credit and Overdraft facilities provide businesses with the flexibility to withdraw funds as needed. These facilities help manage short-term working capital requirements effectively.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Project Finance',
      content: 'Project Finance provides long-term funding for infrastructure and industrial projects. We offer comprehensive financial solutions to support the successful completion of your projects.',
      aosEffect: 'fade-up'
    }
  ];

const CERTIFICATION_SERVICE_DATA = [
    {
      title: 'Startup India Certificate',
      content: 'The Startup India Certificate is a government-issued certification that recognizes and supports startups in India. It provides benefits like tax exemptions, access to funding, and easier compliance.',
      aosEffect: 'fade-up'
    },
    {
      title: 'MSME/Udyam Certificate',
      content: 'The MSME/Udyam Certificate is essential for businesses classified as Micro, Small, or Medium Enterprises. It provides access to various government schemes, subsidies, and incentives designed to promote MSMEs.',
      aosEffect: 'fade-up'
    },
    {
      title: 'ISO Certificate',
      content: 'The ISO Certificate is an international standard that ensures the quality, safety, and efficiency of products and services. It enhances customer satisfaction and improves business processes.',
      aosEffect: 'fade-up'
    },
    {
      title: 'IEC Code Certificate',
      content: 'The Importer Exporter Code (IEC) Certificate is required for businesses engaged in import and export activities in India. It is a mandatory registration for carrying out international trade.',
      aosEffect: 'fade-up'
    },
    {
      title: 'FSSAI Certificate',
      content: 'The FSSAI Certificate is mandatory for businesses involved in food production, processing, storage, distribution, and sale in India. It ensures that food products meet safety and quality standards.',
      aosEffect: 'fade-up'
    },
    {
      title: 'GST Certificate',
      content: 'The GST Certificate is required for businesses whose turnover exceeds the threshold limit under the Goods and Services Tax (GST) regime. It is essential for collecting and remitting GST to the government.',
      aosEffect: 'fade-up'
    }
  ];

const BUSINESS_REGISTRATION_DATA = [
    {
      title: 'Proprietorship',
      content: 'A Proprietorship is the simplest form of business entity that is owned and operated by a single individual. It is easy to set up with minimal regulatory requirements, making it ideal for small-scale businesses.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Partnership Firm/LLP',
      content: 'A Partnership Firm is a business entity where two or more individuals manage and operate the business in accordance with the terms and objectives set out in a Partnership Deed. A Limited Liability Partnership (LLP) offers the flexibility of a partnership with the added benefit of limited liability protection.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Private/Public Limited Company',
      content: 'A Private Limited Company is a corporate entity that limits the liability of shareholders to their shares. It is ideal for businesses that require higher levels of capital and wish to separate personal and business liabilities. A Public Limited Company is similar but allows the company to sell shares to the public and raise capital from them.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Nidhi Company',
      content: 'A Nidhi Company is a type of Non-Banking Financial Company (NBFC) that deals with the lending and borrowing of funds among its members. It promotes the habit of savings among its members and operates within a specific community.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Producer Company',
      content: 'A Producer Company is a legally recognized entity that allows primary producers like farmers and artisans to join forces for better financial stability. It provides a platform for producers to access markets, share profits, and make collective decisions.',
      aosEffect: 'fade-up'
    },
    {
      title: 'Joint Venture',
      content: 'A Joint Venture is a business arrangement where two or more parties agree to pool their resources for a specific project or business activity. It is a strategic alliance that allows partners to share risks and rewards.',
      aosEffect: 'fade-up'
    }
  ];
  
  const OTHER_SERVICES_DATA = [
    {
      title: 'Business Management Consultancy',
      content: 'Our Business Management Consultancy services help organizations improve performance and efficiency. We provide expert advice on management practices, strategy development, and organizational restructuring.',
      aosEffect: 'fade-up',
    },
    {
      title: 'LEI Registration',
      content: 'Legal Entity Identifier (LEI) Registration is essential for entities engaged in financial transactions. We assist businesses in obtaining and renewing LEIs, ensuring compliance with global regulations.',
      aosEffect: 'fade-up',
    },
    {
      title: 'Project Reports',
      content: 'Our experts create detailed Project Reports that cover financial projections, feasibility studies, and market analysis. These reports help businesses secure funding and make informed decisions.',
      aosEffect: 'fade-up',
    },
    {
      title: 'CIBIL Consultancy',
      content: 'We offer CIBIL Consultancy services to help individuals and businesses improve their credit scores. Our experts provide guidance on managing credit health and resolving disputes with credit bureaus.',
      aosEffect: 'fade-up',
    },
    {
      title: 'Business/Shares Valuations',
      content: 'Our Business and Shares Valuations services provide accurate assessments of business worth and share value. We ensure that valuations are conducted in line with industry standards and regulatory requirements.',
      aosEffect: 'fade-up',
    },
    {
      title: 'Taxation Services',
      content: 'Our Taxation Services cover all aspects of tax compliance and planning. We provide expert advice on tax-saving strategies, filing returns, and handling audits, ensuring that your business stays compliant.',
      aosEffect: 'fade-up',
    },
    {
      title: 'Accountancy Services',
      content: 'We offer comprehensive Accountancy Services, including bookkeeping, financial reporting, and audit preparation. Our services are designed to help businesses maintain accurate financial records and meet regulatory requirements.',
      aosEffect: 'fade-up',
    },
    {
      title: 'GST Filing Services',
      content: 'Our GST Filing Services ensure timely and accurate submission of GST returns. We help businesses navigate the complexities of GST compliance, avoiding penalties and ensuring smooth operations.',
      aosEffect: 'fade-up',
    },
    {
      title: 'Mutual Fund',
      content: 'Our Investment Services include Mutual Fund advisory, where we help clients choose the best mutual funds based on their risk profile and financial goals. We provide personalized investment strategies to maximize returns.',
      aosEffect: 'fade-up',
    },
    {
      title: 'Insurance',
      content: 'Our Insurance Services cover a range of insurance products, including life, health, and general insurance. We provide expert advice to help clients select the right policies to protect their assets and loved ones.',
      aosEffect: 'fade-up',
    },
    {
      title: 'DMAT Services',
      content: 'Our DMAT Services offer secure and convenient management of your securities. We assist with account opening, transferring shares, and maintaining your investment portfolio, ensuring a hassle-free experience.',
      aosEffect: 'fade-up',
    }
  ];
  
 const STATUS = {
  SENT_FOR_PROCESS : 'sent_for_process',
  UNDER_REVIEW : 'under_review',
  IN_PROGRESS : 'in_progress',
  CLOSED : 'closed'
 }

export {
    FINANCIAL_SERVICE_DATA,
    CERTIFICATION_SERVICE_DATA,
    BUSINESS_REGISTRATION_DATA,
    OTHER_SERVICES_DATA,
    STATUS
}