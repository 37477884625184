import React from 'react';
import Carousel from '../components/carousel';
import Services from '../components/services';
import AboutUs from '../components/about-us';
import ClientsSection from '../components/clients'

const Home = () => {
  return (
    <div className='bg-gradient-to-r from-blue-100 to-white'>
      <Carousel/>
      <AboutUs/>
      <Services/>
      <ClientsSection/>
    </div>
  );
};

export default Home;