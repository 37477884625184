import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { SubNavigation } from './all-services';
import { Link } from 'react-router-dom';

AOS.init();

const ServiceCard = ({ serviceData, title }) => {
  return (
    <div className="font-poppins">
      <section className="bg-white py-12">
        <div className="container mx-auto px-4">
          <SubNavigation />
          <h2 className="text-4xl font-bold mb-2 text-center">{title}</h2>
          <div className="w-64 h-1 bg-blue-500 mx-auto mb-8"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {serviceData?.map((service, index) => (
              <div
                key={index}
                className="p-6 rounded-lg shadow-xl bg-gradient-to-r from-blue-100 to-white text-gray-800 flex flex-col justify-between"
                data-aos={service.aosEffect}
                data-aos-duration="1000"
              >
                <div>
                  <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                  <p className="text-base">{service.content}</p>
                </div>
                <div className="mt-4 flex justify-end">
                  <Link
                    to="/login"
                    className="py-2 px-4 text-black bg-gradient-to-r from-blue-300 to-blue-600 rounded hover:from-pink-200 hover:to-purple-400 transition duration-300 shadow-lg hover:shadow-xl"
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceCard;
