import React from 'react';
import 'aos/dist/aos.css';

const AboutUs = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <div data-aos="fade-up" data-aos-duration="1000">
        <h1 className="text-4xl font-bold text-center mb-8">About Us</h1>
        
        <p className="text-lg text-gray-700 mb-6 text-justify">
          FINCRED Capital Advisors is a premier Banking Advisory Firm established by seasoned professionals and first-generation entrepreneurs with exceptional experience in the Financial Services industry. We pride ourselves on our ability to deliver high-quality, independent strategic financial advice tailored to our clients' unique needs.
        </p>
        
        <p className="text-lg text-gray-700 mb-6 text-justify ">
          Our mission is to be the "Independent and Trusted Advisor of the first choice" for our clients. We strive to make a tangible impact on their businesses by offering top-notch advisory and transaction execution services. Our dedicated team leverages deep market knowledge and an extensive network to provide actionable insights and effective solutions.
        </p>
        
        <p className="text-lg text-gray-700 mb-6 text-justify ">
          At FINCRED Capital, we believe in forging strong, long-lasting relationships with our clients. Our approach involves thorough analysis, continuous engagement, and a commitment to delivering results. We understand the complexities of the financial landscape and work diligently to ensure that our clients navigate these challenges with confidence and ease.
        </p>

        <p className="text-lg text-gray-700 mb-6 text-justify ">
          Our expertise extends across various areas including wealth management, investment strategies, business certification, and compliance. We provide customized solutions designed to meet the evolving needs of our clients, from start-ups to established enterprises. By staying ahead of industry trends and regulatory changes, we ensure that our clients remain competitive and well-positioned for success.
        </p>
        
        <p className="text-lg text-gray-700 text-justify">
          Choosing FINCRED Capital means partnering with a firm that values integrity, transparency, and excellence. We are dedicated to making a meaningful difference in our clients' financial journeys and are committed to achieving their goals with the highest level of professionalism and expertise.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
